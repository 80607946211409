import slide1ImageMobile from '../../../images/home/slides/home-slide-1-mobile.png';
import slide1Image from '../../../images/home/slides/home-slide-1.png';
import slide2Image from '../../../images/home/slides/home-slide-2.png';
import slide3Image from '../../../images/home/slides/home-slide-3.png';
import slide4Image from '../../../images/home/slides/home-slide-4.png';
import slide5Image from '../../../images/logos/logo-no-text.png';

export const desktopSliderData = [
    {
        number: '01',
        image: slide1Image,
        title: 'Experts in E-Commerce',
        description: 'Experts in E-Commerce',
        subtitle: 'Your Business, Accelerated…',
        content:
            'We deliver award winning marketing and development that consistently performs and drives your businesses forward.',
        link: '/',
        readMoreText: 'Watch the video',
    },
    {
        number: '02',
        image: slide2Image,
        title: 'We are problem solvers',
        subtitle: 'About',
        content:
            'We’re a collaborative team of people all who share the same goal of getting things done and improving your business.',
        link: '/about',
        readMoreText: 'Learn more about us',
    },
    {
        number: '03',
        image: slide3Image,
        title: 'Accelerate your business',
        subtitle: 'E-Commerce',
        content:
            'Developed for retail, tailored for your business. No matter the size of your goals we can help your business take it’s next steps.',
        link: '/ecommerce',
        readMoreText: 'View our e-commerce services',
    },
    {
        number: '04',
        image: slide4Image,
        title: 'Moving forward with you',
        subtitle: 'Digital Marketing',
        content:
            'We use intelligent techniques to make informed choices, serving the right message, to the right people, at the right time.',
        link: '/digital-marketing',
        readMoreText: 'View our digital services',
    },
    {
        number: '05',
        image: slide5Image,
        title: 'Get in touch',
        subtitle: 'Contact Us',
        content:
            'Let’s get things moving, if you would like to learn more or to work with us please get in touch and we’ll get the right solution for you.',
        link: '/contact',
    },
];

export const mobileSliderData = [
    {
        image: slide1ImageMobile,
        subtitle: 'Your Business, Accelerated…',
        content:
            'We deliver award winning marketing and development that consistently performs and drives your businesses forward.',
        link: '/',
        readMoreText: 'Explore',
    },
    {
        image: slide2Image,
        subtitle: 'About',
        content:
            'We’re a collaborative team of people all who share the same goal of getting things done and improving your business.',
        link: '/about',
        readMoreText: 'Learn more about us',
    },
    {
        image: slide3Image,
        subtitle: 'E-Commerce',
        content:
            'Developed for retail, tailored for your business. No matter the size of your goals we can help your business take it’s next steps.',
        link: '/ecommerce',
        readMoreText: 'View our services',
    },
    {
        image: slide4Image,
        subtitle: 'Digital Marketing',
        content:
            'We use intelligent techniques to make informed choices, serving the right message, to the right people, at the right time.',
        link: '/digital-marketing',
        readMoreText: 'What we can do',
    },
    {
        image: slide5Image,
        subtitle: 'Get in touch',
        content:
            'Let’s get things moving, if you would like to learn more or to work with us please get in touch and we’ll get the right solution for you.',
        link: '/contact',
    },
];
