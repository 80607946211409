import React, { useState, useContext } from 'react';
import styled from 'styled-components';
import { HomepageSlideContext } from '../../../context/homepageSlideContext';

import { mobileSliderData } from './sliderData';
import MobileSlide from './mobileSlide';

const Slider = styled.div`
    display: block;
    position: relative;
    width: 100vw;
    height: 100vh;
    height: calc(var(--vh, 1vh) * 100);
    overflow: hidden;
    &[data-active-slide='1'],
    &[data-active-slide='3'] {
        .dots {
            button {
                background: #0e1828;
                transition: 1.5s ease-out background;
            }
        }
        button.next-slide,
        button.prev-slide {
            svg {
                path {
                    fill: #0e1828;
                    transition: 1.5s ease-out fill;
                }
            }
        }
    }
    .dots {
        display: block;
        position: absolute;
        left: 4rem;
        right: 4rem;
        bottom: 3rem;
        font-size: 0;
        z-index: 15;
        animation: fadeIn 0.5s ease-out 4.5s forwards;
        opacity: 0;
        text-align: center;
        font-size: 0;
        button {
            display: inline-block;
            position: relative;
            -webkit-appearance: none;
            appearance: none;
            width: 27px;
            height: 2px;
            border: none;
            background: #fff;
            padding: 0;
            margin-right: 0.5rem;
            cursor: none;
            transition: 1.5s ease-out background;
            .text-wrapper {
                display: none;
            }
            &.active {
                background: #e2246e;
            }
        }
    }
    button.next-slide,
    button.prev-slide {
        display: block;
        -webkit-appearance: none;
        appearance: none;
        position: absolute;
        bottom: 1.5rem;
        right: 4rem;
        height: 100%;
        width: 3.5rem;
        height: 3.5rem;
        border: none;
        background: transparent;
        text-align: center;
        font-size: 0;
        cursor: none;
        z-index: 15;
        opacity: 0;
        animation: fadeIn 0.5s ease-out 4.5s forwards;
        padding: 0;
        svg {
            width: 3.5rem;
            height: auto;
            path {
                transition: 1.5s ease-out fill;
            }
        }
    }
    button.prev-slide {
        right: auto;
        left: 4rem;
    }
`;

const MobileSlider = (props) => {
    const { activeSlide, slideChangeHandler } = useContext(HomepageSlideContext);

    const [transitioning, setTransitioning] = useState(0);
    const length = 5;

    const nextAvailableSlide = activeSlide === length - 1 ? 0 : activeSlide + 1;

    const prevSlide = () => {
        var existingSlide = document.body.getElementsByClassName('active-slide');
        var nextSlide = document.body.getElementsByClassName('next-slide');
        var previousSlide = existingSlide[0].previousSibling;
        nextSlide[0].classList.remove('next-slide');
        previousSlide.classList.add('next-slide');
        setTransitioning(true);
        setTimeout(() => {
            setTransitioning(false);
            slideChangeHandler(activeSlide === 0 ? 5 : activeSlide - 1);
        }, 1500);
    };

    const nextSlide = () => {
        setTransitioning(true);
        setTimeout(() => {
            setTransitioning(false);
            slideChangeHandler(activeSlide === length - 1 ? 0 : activeSlide + 1);
        }, 1500);
    };

    const goToSlide = (value) => {
        var slides = document.body.getElementsByClassName('slide');
        var nextSlide = document.body.getElementsByClassName('next-slide');
        nextSlide[0].classList.remove('next-slide');
        slides[value].classList.add('next-slide');
        setTransitioning(true);
        setTimeout(() => {
            setTransitioning(false);
            slideChangeHandler(value);
        }, 1500);
    };

    return (
        <Slider data-active-slide={activeSlide}>
            {mobileSliderData.map((slide, index) => {
                return (
                    <MobileSlide
                        key={index}
                        index={index}
                        nextSlide={nextSlide}
                        classes={
                            'slide' +
                            (index === activeSlide && transitioning === true
                                ? ' transition-out'
                                : '') +
                            (index === activeSlide ? ' active-slide' : '') +
                            (index === nextAvailableSlide ? ' next-slide' : '')
                        }
                        slide={slide}
                        current={index === activeSlide ? true : false}
                    />
                );
            })}
            <button className="prev-slide" onClick={prevSlide}>
                Previous
                <svg
                    width="44"
                    height="18"
                    viewBox="0 0 44 18"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M6.29409 8.0753L43.9189 8.0753L43.9189 10.2163L6.29409 10.2163L6.29409 8.0753Z"
                        fill="#fff"
                    />
                    <path
                        d="M11.3528 17.3662L8.5072 9.14565L11.3528 0.925096L0.918992 9.14565L11.3528 17.3662Z"
                        fill="#fff"
                    />
                </svg>
            </button>
            <button className="next-slide" onClick={nextSlide}>
                Next
                <svg width="25" height="10" viewBox="0 0 25 10" fill="none">
                    <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M21.9027 5.61223L0.383423 5.61223L0.383423 4.3877L21.9027 4.3877V5.61223Z"
                        fill="white"
                    />
                    <path
                        d="M19.0095 0.29834L20.637 5.00003L19.0095 9.70173L24.977 5.00003L19.0095 0.29834Z"
                        fill="white"
                    />
                </svg>
            </button>
            <div className="dots">
                {mobileSliderData.map((slide, index) => {
                    return (
                        <button
                            key={index}
                            className={index === activeSlide ? 'active' : null}
                            onClick={() => goToSlide(index)}
                        >
                            <div className="text-wrapper">
                                <span>{slide.subtitle}</span>
                            </div>
                        </button>
                    );
                })}
            </div>
        </Slider>
    );
};

export default MobileSlider;
