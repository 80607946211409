import React, { useContext } from 'react';
import styled from 'styled-components';
import { Link } from 'gatsby';
// import { ContactFormPopupContext } from '../../../context/contactFormPopupContext';
import sendWhite from '../../../images/icons/send-white.png';

const Slide = styled.div`
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    align-items: center;
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background: #0e1828;
    z-index: 0;
    overflow: hidden;
    &.transition-out {
        transform: translateX(-100%);
        transition: 1.5s ease-out all;
        .do-not-move {
            transform: translateX(100%);
            transition: 1.5s ease-out all;
        }
        .image {
            &.do-not-move {
                transform: translateX(100%);
            }
        }
    }
    &.active-slide {
        z-index: 6;
    }
    &.next-slide {
        z-index: 5;
    }
    &.active-slide {
        .image {
            img {
                transform: scale(1);
                transition: 1s ease-out transform !important;
            }
        }
        .content {
            .title-wrapper {
                h2 {
                    transform: translateY(0);
                    transition: 1s ease-out transform !important;
                }
            }
            .content-wrapper {
                p {
                    transform: translateY(0);
                    transition: 1s ease-out transform !important;
                }
            }
            .button-wrapper {
                .read-more-button,
                .explore-button {
                    transform: translateY(0) !important;
                    transition: 1s ease-out transform !important;
                }
            }
        }
        &[data-slide='4'] {
            .image {
                img {
                    transform: scale(30);
                    opacity: 0.3;
                    transition: 0.5s ease-out transform !important;
                }
            }
            .content {
                .contact-links-container {
                    .contact-links {
                        opacity: 1;
                        transition: 1s ease-out opacity !important;
                    }
                }
                .send-message-container {
                    .send-message {
                        transform: translateY(0) !important;
                        transition: 1s ease-out transform !important;
                    }
                }
            }
        }
    }
    &[data-slide='1'],
    &[data-slide='3'] {
        background: #ececec;
        .content {
            .title-wrapper {
                h2 {
                    color: #0e1828;
                }
            }
            .content-wrapper {
                p {
                    color: #0e1828;
                }
            }
            .button-wrapper {
                .read-more-button {
                    color: #0e1828;
                    border-color: #0e1828;
                }
            }
        }
    }
    &[data-slide='0'] {
        .image {
            display: block;
            margin: 0 0 2.5rem;
            img {
                display: block;
                width: 100%;
                max-width: 285px;
                height: auto;
                font-size: 0;
            }
        }
        .content {
            .button-wrapper {
                button.explore-button {
                    display: block;
                    width: 9rem;
                    height: 9rem;
                    border-radius: 50%;
                    background: #ececec;
                    color: #0e1828;
                    font-family: 'Source serif pro';
                    font-size: 1.6rem;
                    line-height: 1.5;
                    font-weight: 400;
                    font-style: italic;
                    border: none;
                    margin: 1.5rem auto 0;
                    transform: translateY(110%);
                    transition: 1s ease-out transform !important;
                    svg {
                        display: block;
                        width: 23px;
                        height: auto;
                        margin: auto;
                    }
                }
            }
        }
    }
    &[data-slide='4'] {
        .image {
            width: 100%;
            height: 100%;
            position: absolute;
            top: 0;
            left: 0;
            pointer-events: none;
            z-index: 0;
            img {
                display: block;
                position: absolute;
                top: 5.5rem;
                left: 2.4rem;
                width: 2.45rem;
                height: auto;
                transform: scale(1);
                transition: 0.5s ease-out transform !important;
                transform-origin: 45% 35%;
            }
        }
        .content {
            z-index: 1;
            position: relative;
            .title-wrapper {
                h2 {
                    font-family: 'Poppins';
                    font-size: 4rem;
                    line-height: 1;
                    font-weight: 600;
                    text-transform: uppercase;
                    color: #fff;
                }
            }
            .content-wrapper {
                p {
                    color: #fff;
                }
            }
            .contact-links-container {
                display: block;
                overflow: hidden;
                margin-top: 3rem;
                .contact-links {
                    display: block;
                    font-size: 0;
                    margin: 0;
                    opacity: 0;
                    transition: 1s ease-out opacity !important;
                    a {
                        display: block;
                        font-family: 'Poppins';
                        font-size: 1.4rem;
                        line-height: 1.35;
                        font-weight: 300;
                        color: #fff;
                        margin: 0 auto 2.5rem;
                        text-decoration: none;
                        text-align: center;
                        max-width: 200px;
                        svg {
                            display: block;
                            width: 1.6rem;
                            height: auto;
                            margin: 0 auto 0.5rem;
                        }
                        &:last-child {
                            margin-bottom: 0;
                        }
                    }
                }
            }
            .send-message-container {
                display: block;
                overflow: hidden;
                pointer-events: all;
                margin-top: 4rem;
                .send-message {
                    display: block;
                    transform: translateY(110%);
                    transition: 1s ease-out transform !important;
                    button {
                        display: inline-block;
                        padding: 1.5rem 2rem;
                        background: #e2246e;
                        color: #fff;
                        font-family: 'Poppins';
                        font-size: 1.1rem;
                        font-weight: 600;
                        line-height: 1.5;
                        letter-spacing: 0.3em;
                        text-transform: uppercase;
                        border: none;
                        border-radius: 25px;
                        pointer-events: all;
                        i {
                            display: inline-block;
                            width: 2.2rem;
                            height: 2.2rem;
                            background: url(${sendWhite}) center/contain no-repeat;
                            vertical-align: middle;
                            margin-left: 2rem;
                        }
                    }
                }
            }
        }
    }
    .image {
        display: block;
        flex: 0 1 auto;
        width: 100%;
        padding: 0 2rem;
        img {
            display: block;
            width: 100%;
            max-width: 335px;
            margin: auto;
        }
    }
    .content {
        display: block;
        flex: 0 1 auto;
        width: 100%;
        text-align: center;
        padding: 0 2rem;
        .title-wrapper {
            display: block;
            overflow: hidden;
            h2 {
                font-family: 'Source Serif Pro';
                font-size: 1.6rem;
                line-height: 1.375;
                font-weight: 400;
                margin: 0 0 0.5rem 0;
                transform: translateY(110%);
                transition: 1s ease-out transform !important;
                color: #fff;
            }
        }
        .content-wrapper {
            display: block;
            overflow: hidden;
            p {
                font-family: 'Poppins';
                font-size: 1.4rem;
                line-height: 1.5;
                max-width: 350px;
                margin: 0 auto;
                color: #fff;
                transform: translateY(110%);
                transition: 1s ease-out transform !important;
            }
        }
        .button-wrapper {
            display: block;
            width: 100%;
            margin-top: 3rem;
            overflow: hidden;
            .read-more-button {
                display: block;
                width: 100%;
                max-width: 265px;
                border: 1px solid #fff;
                border-radius: 30px;
                padding: 1.5rem;
                background: transparent;
                margin: 0 auto;
                font-family: 'Poppins';
                font-size: 1rem;
                line-height: 1.5;
                font-weight: 600;
                letter-spacing: 0.3em;
                text-transform: uppercase;
                color: #fff;
                transform: translateY(110%);
                transition: 1s ease-out transform !important;
                text-decoration: none;
            }
        }
    }
`;

const MobileSlide = ({ ...props }) => {
    // const { popupChangeHandler } = useContext(ContactFormPopupContext);
    return props.index === 0 ? (
        <Slide className={props.classes} data-slide={props.index} image={props.slide.image}>
            <div className={'image' + (props.current ? ' do-not-move' : '')}>
                <img src={props.slide.image} alt="" />
            </div>
            <div className={'content' + (props.current ? ' do-not-move' : '')}>
                <div className="title-wrapper">
                    <h2>{props.slide.subtitle}</h2>
                </div>
                <div className="content-wrapper">
                    <p>{props.slide.content}</p>
                </div>
                <div className="button-wrapper">
                    <button className="explore-button" onClick={props.nextSlide}>
                        {props.slide.readMoreText}
                        <svg width="25" height="10" viewBox="0 0 25 10" fill="none">
                            <path
                                fillRule="evenodd"
                                clipRule="evenodd"
                                d="M21.9027 5.61223L0.383423 5.61223L0.383423 4.3877L21.9027 4.3877V5.61223Z"
                                fill="#0E1828"
                            />
                            <path
                                d="M19.0095 0.29834L20.637 5.00003L19.0095 9.70173L24.977 5.00003L19.0095 0.29834Z"
                                fill="#0E1828"
                            />
                        </svg>
                    </button>
                </div>
            </div>
        </Slide>
    ) : props.index === 4 ? (
        <Slide className={props.classes} data-slide={props.index} image={props.slide.image}>
            <div className={'image' + (props.current ? ' do-not-move' : '')}>
                <img src={props.slide.image} alt="" />
            </div>
            <div className={'content' + (props.current ? ' do-not-move' : '')}>
                <div className="title-wrapper">
                    <h2>{props.slide.subtitle}</h2>
                </div>
                <div className="content-wrapper">
                    <p>{props.slide.content}</p>
                </div>
                <div className="contact-links-container">
                    <div className="contact-links">
                        <a className="email" href="mailto:info@digital-velocity.co.uk">
                            <svg
                                width="17"
                                height="12"
                                viewBox="0 0 17 12"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <path
                                    fillRule="evenodd"
                                    clipRule="evenodd"
                                    d="M15.4456 0.667725H1.52409C1.02788 0.667725 0.634104 1.03764 0.559524 1.51395L8.48485 6.73552L16.4102 1.51395C16.3356 1.03764 15.9418 0.667725 15.4456 0.667725ZM0.529541 9.60937V2.6844L5.89331 6.21848L0.529541 9.60937ZM16.44 9.60937L11.0763 6.21848L16.44 2.6844V9.60937ZM8.7583 7.74591L10.1654 6.81913L16.4082 10.7669C16.3316 11.2392 15.9398 11.6061 15.4456 11.6061H1.52408C1.02986 11.6061 0.638071 11.2392 0.561502 10.7669L6.80431 6.81814L8.21138 7.74591C8.29491 7.8006 8.38938 7.82745 8.48484 7.82745C8.5803 7.82745 8.67477 7.8006 8.7583 7.74591Z"
                                    fill="white"
                                />
                            </svg>
                            info@digital-velocity.co.uk
                        </a>
                        <a className="phone" href="tel:01134601202">
                            <svg
                                width="17"
                                height="16"
                                viewBox="0 0 17 16"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <path
                                    d="M15.2457 10.5022C14.2661 10.5022 13.3043 10.349 12.3928 10.0478C11.9462 9.89542 11.3971 10.0352 11.1246 10.3151L9.32552 11.6732C7.23915 10.5595 5.95398 9.27475 4.85549 7.20404L6.17361 5.45188C6.51607 5.10988 6.6389 4.61029 6.49173 4.14154C6.18923 3.22529 6.03557 2.26392 6.03557 1.28392C6.03561 0.575958 5.45965 0 4.75174 0H1.81341C1.1055 0 0.529541 0.575958 0.529541 1.28388C0.529541 9.39846 7.13111 16 15.2457 16C15.9536 16 16.5295 15.424 16.5295 14.7161V11.786C16.5295 11.0781 15.9535 10.5022 15.2457 10.5022Z"
                                    fill="white"
                                />
                            </svg>
                            0113 460 1202
                        </a>
                        <a
                            className="directions"
                            href="https://goo.gl/maps/TfAk2zyFso84GyKdA"
                            target="_blank"
                            rel="noreferrer"
                        >
                            <svg
                                width="15"
                                height="21"
                                viewBox="0 0 15 21"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <path
                                    d="M14.1758 6.50162C14.0462 5.07105 13.5709 3.85086 12.9659 2.79897C12.4042 2.37822 11.8425 2.04162 11.2375 1.78916C9.9412 1.20011 8.47206 0.905578 7.08933 0.989729C6.57081 1.0318 6.05229 1.07388 5.53376 1.20011C4.97203 1.32633 4.4103 1.49463 3.89178 1.74709C3.28683 1.99954 2.68189 2.37822 2.16337 2.7569C1.472 3.89294 1.08311 5.1552 0.910272 6.45954C0.780641 7.42728 0.867062 8.39501 1.08311 9.40483C1.29916 10.3726 1.64485 11.2982 2.12016 12.1397L7.52143 20.9756L13.0091 12.1818C13.4844 11.3403 13.8301 10.4146 14.003 9.4469C14.1758 8.43709 14.2622 7.46935 14.1758 6.50162ZM7.52143 3.89294C9.55231 3.89294 11.1943 5.49181 11.1943 7.46935C11.1943 9.4469 9.55231 11.0458 7.52143 11.0458C5.49055 11.0458 3.84857 9.4469 3.84857 7.46935C3.84857 5.49181 5.49055 3.89294 7.52143 3.89294Z"
                                    fill="white"
                                />
                            </svg>
                            Get Directions
                        </a>
                    </div>
                </div>
                {/* <div className="send-message-container">
                    <div className="send-message">
                        <button className="send-a-message" onClick={() => popupChangeHandler(true)}>
                            Send a message <i></i>
                        </button>
                    </div>
                </div> */}
            </div>
        </Slide>
    ) : (
        <Slide className={props.classes} data-slide={props.index} image={props.slide.image}>
            <div className={'image' + (props.current ? ' do-not-move' : '')}>
                <img src={props.slide.image} alt="" />
            </div>
            <div className={'content' + (props.current ? ' do-not-move' : '')}>
                <div className="title-wrapper">
                    <h2>{props.slide.subtitle}</h2>
                </div>
                <div className="content-wrapper">
                    <p>{props.slide.content}</p>
                </div>
                <div className="button-wrapper">
                    <Link to={props.slide.link} className="read-more-button">
                        {props.slide.readMoreText}
                    </Link>
                </div>
            </div>
        </Slide>
    );
};

export default MobileSlide;
