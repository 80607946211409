import React, { useContext } from 'react';
import styled from 'styled-components';
import Vimeo from '@u-wave/react-vimeo';
import { VideoPopupContext } from '../../context/videoPopupContext';

import closeWhite from '../../images/icons/close-white.png';

const Wrapper = styled.div`
    display: none;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(13, 24, 40, 0.7);
    z-index: 100;
    &.active {
        display: block;
    }
    .outer-wrapper {
        display: block;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 100%;
        max-width: 300px;
        max-height: 80%;
        overflow-y: hidden;
        margin: auto;
        z-index: 101;
        -ms-overflow-style: none;
        scrollbar-width: none;
        &::-webkit-scrollbar {
            display: none;
        }
        .inner-wrapper {
            display: block;
            position: relative;
            width: 100%;
            background: #0e1828;
            button.close-icon {
                display: block;
                position: absolute;
                top: 2rem;
                right: 2rem;
                width: 4rem;
                height: 4rem;
                font-size: 0;
                background: url(${closeWhite}) center/2.4rem auto no-repeat;
                border: none;
                z-index: 1;
            }
        }
    }
    @media only screen and (min-width: 768px) {
        .outer-wrapper {
            max-width: 90%;
            max-height: 90%;
            video {
                width: auto;
                height: 100%;
            }
        }
    }
`;

const VideoPopup = (props) => {
    const { showVideoPopup, videoPopupChangeHandler } = useContext(VideoPopupContext);

    return (
        <Wrapper className={showVideoPopup ? 'active' : null}>
            <div className="outer-wrapper">
                <div className="inner-wrapper">
                    <button className="close-icon" onClick={() => videoPopupChangeHandler(false)}>
                        Close
                    </button>
                    <Vimeo
                        video="https://player.vimeo.com/video/628995853"
                        showPortrait={false}
                        showtitle={false}
                        showByline={false}
                        controls={false}
                        responsive
                        paused={showVideoPopup ? false : true}
                    />
                </div>
            </div>
        </Wrapper>
    );
};

export default VideoPopup;
