import React, { useEffect, useState } from 'react';
import styled from 'styled-components';

const n = 30;

const IntroWrapper = styled.div`
    @keyframes yourText {
        0% {
            opacity: 0;
            transform: translateY(0);
        }
        62.5% {
            opacity: 1;
            transform: translateY(0);
        }
        100% {
            opacity: 1;
            transform: translateY(100%);
        }
    }

    @keyframes businessText {
        0% {
            opacity: 1;
            transform: translateY(-100%);
        }
        62.5% {
            opacity: 1;
            transform: translateY(0);
        }
        100% {
            opacity: 0;
            transform: translateY(0);
        }
    }

    @keyframes logoBgStroke1 {
        0% {
            stroke-dashoffset: 133.16209411621094px;
            stroke-dasharray: 133.16209411621094px;
        }
        45.715% {
            stroke-dashoffset: 0;
            stroke-dasharray: 133.16209411621094px;
        }
        54.285% {
            stroke-dashoffset: 0;
            stroke-dasharray: 133.16209411621094px;
        }
        100% {
            stroke-dashoffset: 133.16209411621094px;
            stroke-dasharray: 133.16209411621094px;
        }
    }

    @keyframes logoBgStroke2 {
        0% {
            stroke-dashoffset: 151.68328857421875px;
            stroke-dasharray: 151.68328857421875px;
        }
        45.715% {
            stroke-dashoffset: 0;
            stroke-dasharray: 151.68328857421875px;
        }
        54.285% {
            stroke-dashoffset: 0;
            stroke-dasharray: 151.68328857421875px;
        }
        100% {
            stroke-dashoffset: 151.68328857421875px;
            stroke-dasharray: 151.68328857421875px;
        }
    }

    @keyframes logoBgStroke3 {
        0% {
            stroke-dashoffset: 155.3725128173828px;
            stroke-dasharray: 155.3725128173828px;
        }
        45.715% {
            stroke-dashoffset: 0;
            stroke-dasharray: 155.3725128173828px;
        }
        54.285% {
            stroke-dashoffset: 0;
            stroke-dasharray: 155.3725128173828px;
        }
        100% {
            stroke-dashoffset: 155.3725128173828px;
            stroke-dasharray: 155.3725128173828px;
        }
    }

    @keyframes logoBgStroke4 {
        0% {
            stroke-dashoffset: 144.6675262451172px;
            stroke-dasharray: 144.6675262451172px;
        }
        45.715% {
            stroke-dashoffset: 0;
            stroke-dasharray: 144.6675262451172px;
        }
        54.285% {
            stroke-dashoffset: 0;
            stroke-dasharray: 144.6675262451172px;
        }
        100% {
            stroke-dashoffset: 144.6675262451172px;
            stroke-dasharray: 144.6675262451172px;
        }
    }

    @keyframes logoBgStroke5 {
        0% {
            stroke-dashoffset: 766.907958984375px;
            stroke-dasharray: 766.907958984375px;
        }
        45.715% {
            stroke-dashoffset: 0;
            stroke-dasharray: 766.907958984375px;
        }
        54.285% {
            stroke-dashoffset: 0;
            stroke-dasharray: 766.907958984375px;
        }
        100% {
            stroke-dashoffset: 766.907958984375px;
            stroke-dasharray: 766.907958984375px;
        }
    }

    @keyframes logoBgStroke6 {
        0% {
            stroke-dashoffset: 1190.564453125px;
            stroke-dasharray: 1190.564453125px;
        }
        45.715% {
            stroke-dashoffset: 0;
            stroke-dasharray: 1190.564453125px;
        }
        54.285% {
            stroke-dashoffset: 0;
            stroke-dasharray: 1190.564453125px;
        }
        100% {
            stroke-dashoffset: 1190.564453125px;
            stroke-dasharray: 1190.564453125px;
        }
    }

    @keyframes logoBgStroke7 {
        0% {
            stroke-dashoffset: 1192.122314453125px;
            stroke-dasharray: 1192.122314453125px;
        }
        45.715% {
            stroke-dashoffset: 0;
            stroke-dasharray: 1192.122314453125px;
        }
        54.285% {
            stroke-dashoffset: 0;
            stroke-dasharray: 1192.122314453125px;
        }
        100% {
            stroke-dashoffset: 1192.122314453125px;
            stroke-dasharray: 1192.122314453125px;
        }
    }

    @keyframes logoBgStroke8 {
        0% {
            stroke-dashoffset: 756.7937622070312px;
            stroke-dasharray: 756.7937622070312px;
        }
        45.715% {
            stroke-dashoffset: 0;
            stroke-dasharray: 756.7937622070312px;
        }
        54.285% {
            stroke-dashoffset: 0;
            stroke-dasharray: 756.7937622070312px;
        }
        100% {
            stroke-dashoffset: 756.7937622070312px;
            stroke-dasharray: 756.7937622070312px;
        }
    }

    @keyframes logoBgStroke9 {
        0% {
            stroke-dashoffset: 2063.95947265625px;
            stroke-dasharray: 2063.95947265625px;
        }
        45.715% {
            stroke-dashoffset: 0;
            stroke-dasharray: 2063.95947265625px;
        }
        54.285% {
            stroke-dashoffset: 0;
            stroke-dasharray: 2063.95947265625px;
        }
        100% {
            stroke-dashoffset: 2063.95947265625px;
            stroke-dasharray: 2063.95947265625px;
        }
    }

    @keyframes accelerateText {
        0% {
            transform: translateY(100%);
        }
        45.715% {
            transform: translateY(0);
        }
        54.285% {
            transform: translateY(0);
        }
        100% {
            transform: translateY(-100%);
        }
    }

    display: block;
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    height: calc(var(--vh, 1vh) * 100);
    z-index: 200;
    background: #0e1828;
    .title-wrapper {
        display: block;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 50%;
        text-align: center;
        .title {
            display: block;
            position: relative;
            overflow: hidden;
            &.first {
                span {
                    animation: yourText 0.8s ease-out forwards;
                }
            }
            &.second {
                span {
                    animation: businessText 0.8s ease-out 0.7s forwards;
                    font-weight: 600;
                }
            }
            span {
                display: block;
                text-align: center;
                color: #fff;
                font-family: 'Poppins';
                font-size: 4rem;
                line-height: 1;
                font-weight: 300;
                transform: translateY(100%);
            }
        }
    }
    .logo-background {
        display: flex;
        flex-flow: column nowrap;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        pointer-events: none;
        overflow: hidden;
        .row {
            display: flex;
            flex: 0 1 auto;
            flex-flow: row nowrap;
            align-items: center;
            justify-content: center;
            width: 100%;
            overflow: visible;
            &:nth-child(odd) {
                margin-left: -150px;
            }
            &:nth-child(even) {
                margin-left: -75px;
            }
            svg {
                display: block;
                flex: 0 0 auto;
                &.logo {
                    margin: 12.5px;
                    width: 25px;
                    .path-1 {
                        stroke-dashoffset: 133.16209411621094px;
                        stroke-dasharray: 133.16209411621094px;
                        animation: logoBgStroke1 2s ease-out 1.5s forwards;
                    }
                    .path-2 {
                        stroke-dashoffset: 151.68328857421875px;
                        stroke-dasharray: 151.68328857421875px;
                        animation: logoBgStroke2 2s ease-out 1.5s forwards;
                    }
                    .path-3 {
                        stroke-dashoffset: 155.3725128173828px;
                        stroke-dasharray: 155.3725128173828px;
                        animation: logoBgStroke3 2s ease-out 1.5s forwards;
                    }
                    .path-4 {
                        stroke-dashoffset: 144.6675262451172px;
                        stroke-dasharray: 144.6675262451172px;
                        animation: logoBgStroke4 2s ease-out 1.5s forwards;
                    }
                    .path-5 {
                        stroke-dashoffset: 766.907958984375px;
                        stroke-dasharray: 766.907958984375px;
                        animation: logoBgStroke5 2s ease-out 1.5s forwards;
                    }
                    .path-6 {
                        stroke-dashoffset: 1190.564453125px;
                        stroke-dasharray: 1190.564453125px;
                        animation: logoBgStroke6 2s ease-out 1.5s forwards;
                    }
                    .path-7 {
                        stroke-dashoffset: 1192.122314453125px;
                        stroke-dasharray: 1192.122314453125px;
                        animation: logoBgStroke7 2s ease-out 1.5s forwards;
                    }
                    .path-8 {
                        stroke-dashoffset: 756.7937622070312px;
                        stroke-dasharray: 756.7937622070312px;
                        animation: logoBgStroke8 2s ease-out 1.5s forwards;
                    }
                    .path-9 {
                        stroke-dashoffset: 2063.95947265625px;
                        stroke-dasharray: 2063.95947265625px;
                        animation: logoBgStroke9 2s ease-out 1.5s forwards;
                    }
                }
            }
            .accelerate-text {
                display: block;
                position: relative;
                flex: 0 0 auto;
                width: 88px;
                height: 25px;
                overflow: hidden;
                margin: 12.5px;
                svg {
                    &.text {
                        transform: translateY(100%);
                        animation: accelerateText 1.7s ease-out 1.8s forwards;
                        width: 100%;
                    }
                }
            }
        }
    }
    @media only screen and (min-width: 768px) {
        .title-wrapper {
            .title {
                span {
                    font-size: 8rem;
                }
            }
        }
        .logo-background {
            .row {
                &:nth-child(odd) {
                    margin-left: -150px;
                }
                &:nth-child(even) {
                    margin-left: -520px;
                }
                svg {
                    &.logo {
                        margin: 50px;
                        width: 100px;
                    }
                }
                .accelerate-text {
                    width: 352px;
                    height: 100px;
                    margin: 50px;
                }
            }
        }
    }
    @media only screen and (min-width: 1024px) {
    }
`;

const IntroAnimation = (props) => {
    const [visible, setVisible] = useState(true);

    useEffect(() => {
        setTimeout(() => {
            setVisible(false);
        }, props.delay);
    }, [props.delay]);

    return visible ? (
        <IntroWrapper>
            <div className="title-wrapper">
                <div className="title first">
                    <span>Your</span>
                </div>
                <div className="title second">
                    <span>Business</span>
                </div>
            </div>
            <div className="logo-background">
                {[...Array(n)].map((elementInArray, index) => (
                    <div className="row" key={index}>
                        <svg className="logo" viewBox="0 0 1000 1000">
                            <path
                                className="path-1"
                                fill="none"
                                stroke="#fff"
                                strokeLinejoin="round"
                                strokeWidth="20px"
                                d="M752.7,56.39a746.66,746.66,0,0,1,115.45,61,2.49,2.49,0,0,1,.26.33"
                            />
                            <path
                                className="path-2"
                                fill="none"
                                stroke="#fff"
                                strokeLinejoin="round"
                                strokeWidth="20px"
                                d="M127.63,123l.81-1.1s49-36.58,131.09-68.64"
                            />
                            <path
                                className="path-3"
                                fill="none"
                                stroke="#fff"
                                strokeLinejoin="round"
                                strokeWidth="20px"
                                d="M872.77,874.28c-.47.71-.92,1.43-1.37,2.16,0,0-49.41,38.16-132.62,71"
                            />
                            <path
                                className="path-4"
                                fill="none"
                                stroke="#fff"
                                strokeLinejoin="round"
                                strokeWidth="20px"
                                d="M253.62,943.72a760.63,760.63,0,0,1-125.18-67.28c-.05-.11-.12-.19-.17-.3"
                            />
                            <path
                                className="path-5"
                                fill="none"
                                stroke="#fff"
                                strokeLinejoin="round"
                                strokeWidth="20px"
                                d="M504.82,126.1A693.28,693.28,0,0,1,752.7,56.39C552.52-27.31,373.89,8.63,259.53,53.3"
                            />
                            <path
                                className="path-6"
                                fill="none"
                                stroke="#fff"
                                strokeLinejoin="round"
                                strokeWidth="20px"
                                d="M872.77,874.28c264.72-414.3,5-745-4.36-756.58a700.8,700.8,0,0,1,12.48,132A696.08,696.08,0,0,1,837,493.86"
                            />
                            <path
                                className="path-7"
                                fill="none"
                                stroke="#fff"
                                strokeLinejoin="round"
                                strokeWidth="20px"
                                d="M161.89,504.77A701,701,0,0,1,127.63,123c-16.09,21.54-259.91,360.47.64,753.1"
                            />
                            <path
                                className="path-8"
                                fill="none"
                                stroke="#fff"
                                strokeLinejoin="round"
                                strokeWidth="20px"
                                d="M253.62,943.72c197.94,82.93,372.52,48.22,485.16,3.74A693.21,693.21,0,0,1,494,874.38"
                            />
                            <path
                                className="path-9"
                                fill="none"
                                stroke="#fff"
                                strokeLinejoin="round"
                                strokeWidth="20px"
                                d="M504.82,126.1A699.9,699.9,0,0,0,161.89,504.77,700,700,0,0,0,494,874.38,700,700,0,0,0,837,493.86,699.91,699.91,0,0,0,504.82,126.1Z"
                            />
                        </svg>
                        <div className="accelerate-text">
                            <svg className="text" viewBox="0 0 3512.23 1000">
                                <path
                                    fill="#fff"
                                    d="M218,595H90.8L68.33,658.85h-67L118.21,345.2h72.34L306.94,658.85H240ZM200.89,547,154.16,413.05,107.42,547Z"
                                />
                                <path
                                    fill="#fff"
                                    d="M627.78,369q39.09,30.57,51.68,83.13H613q-10.34-25.61-32.13-40.44t-52.35-14.83q-25.17,0-45.38,12.58T451.63,445.4q-11.24,23.37-11.23,54.82,0,31,11.23,54.38t31.46,35.95q20.22,12.59,45.38,12.58,30.56,0,52.35-14.83T613,547.86h66.51Q666.88,600.43,627.78,631t-98.41,30.56q-45.39,0-80.21-20.45t-53.92-57.29q-19.11-36.84-19.1-83.59,0-47.18,19.1-84t53.69-57.29q34.6-20.44,80.44-20.45Q588.68,338.45,627.78,369Z"
                                />
                                <path
                                    fill="#fff"
                                    d="M1013.78,369q39.09,30.57,51.67,83.13H999q-10.35-25.61-32.13-40.44t-52.35-14.83q-25.17,0-45.39,12.58T837.63,445.4q-11.25,23.37-11.24,54.82,0,31,11.24,54.38t31.45,35.95q20.22,12.59,45.39,12.58,30.56,0,52.35-14.83T999,547.86h66.5q-12.59,52.57-51.67,83.13t-98.41,30.56q-45.4,0-80.22-20.45t-53.92-57.29q-19.11-36.84-19.1-83.59,0-47.18,19.1-84t53.7-57.29q34.59-20.44,80.44-20.45Q974.68,338.45,1013.78,369Z"
                                />
                                <path
                                    fill="#fff"
                                    d="M1221.38,391.93v82.23h112.34v48.53H1221.38v85.38H1347.2v50.78H1158V341.6H1347.2v50.33Z"
                                />
                                <path
                                    fill="#fff"
                                    d="M1502.22,610.32h106.5v48.53H1438.86V341.6h63.36Z"
                                />
                                <path
                                    fill="#fff"
                                    d="M1754.76,391.93v82.23H1867.1v48.53H1754.76v85.38h125.82v50.78H1691.4V341.6h189.18v50.33Z"
                                />
                                <path
                                    fill="#fff"
                                    d="M2137.61,658.85l-74.14-123.57h-27.86V658.85h-63.36V341.6h120.88q55.26,0,84.25,27t29,71q0,36-20,60.22t-57.74,32.35l79.08,126.72Zm-102-167.16h52.12q54.38,0,54.38-48.09,0-22.91-13.26-35.72t-41.12-12.81h-52.12Z"
                                />
                                <path
                                    fill="#fff"
                                    d="M2487.21,595H2360.05l-22.47,63.81h-67L2387.46,345.2h72.34l116.39,313.65h-67ZM2470.14,547l-46.73-133.91L2376.67,547Z"
                                />
                                <path
                                    fill="#fff"
                                    d="M2871,341.6v50.33h-87.63V658.85H2720V391.93h-87.17V341.6Z"
                                />
                                <path
                                    fill="#fff"
                                    d="M3014.31,391.93v82.23h112.34v48.53H3014.31v85.38h125.82v50.78H2951V341.6h189.18v50.33Z"
                                />
                                <path
                                    fill="#fff"
                                    d="M3491.08,582.91q-19.77,36-57.29,55.94t-89.2,20H3231.8V341.6h112.79q51.67,0,89.2,19.55t57.29,55.27q19.77,35.71,19.77,83.36Q3510.85,547,3491.08,582.91Zm-72.57-7.64q28.08-27,28.09-75.49,0-49-28.09-75.95t-79.31-27h-44V602.23h44Q3390.43,602.23,3418.51,575.27Z"
                                />
                            </svg>
                        </div>
                        <svg className="logo" viewBox="0 0 1000 1000">
                            <path
                                className="path-1"
                                fill="none"
                                stroke="#fff"
                                strokeLinejoin="round"
                                strokeWidth="20px"
                                d="M752.7,56.39a746.66,746.66,0,0,1,115.45,61,2.49,2.49,0,0,1,.26.33"
                            />
                            <path
                                className="path-2"
                                fill="none"
                                stroke="#fff"
                                strokeLinejoin="round"
                                strokeWidth="20px"
                                d="M127.63,123l.81-1.1s49-36.58,131.09-68.64"
                            />
                            <path
                                className="path-3"
                                fill="none"
                                stroke="#fff"
                                strokeLinejoin="round"
                                strokeWidth="20px"
                                d="M872.77,874.28c-.47.71-.92,1.43-1.37,2.16,0,0-49.41,38.16-132.62,71"
                            />
                            <path
                                className="path-4"
                                fill="none"
                                stroke="#fff"
                                strokeLinejoin="round"
                                strokeWidth="20px"
                                d="M253.62,943.72a760.63,760.63,0,0,1-125.18-67.28c-.05-.11-.12-.19-.17-.3"
                            />
                            <path
                                className="path-5"
                                fill="none"
                                stroke="#fff"
                                strokeLinejoin="round"
                                strokeWidth="20px"
                                d="M504.82,126.1A693.28,693.28,0,0,1,752.7,56.39C552.52-27.31,373.89,8.63,259.53,53.3"
                            />
                            <path
                                className="path-6"
                                fill="none"
                                stroke="#fff"
                                strokeLinejoin="round"
                                strokeWidth="20px"
                                d="M872.77,874.28c264.72-414.3,5-745-4.36-756.58a700.8,700.8,0,0,1,12.48,132A696.08,696.08,0,0,1,837,493.86"
                            />
                            <path
                                className="path-7"
                                fill="none"
                                stroke="#fff"
                                strokeLinejoin="round"
                                strokeWidth="20px"
                                d="M161.89,504.77A701,701,0,0,1,127.63,123c-16.09,21.54-259.91,360.47.64,753.1"
                            />
                            <path
                                className="path-8"
                                fill="none"
                                stroke="#fff"
                                strokeLinejoin="round"
                                strokeWidth="20px"
                                d="M253.62,943.72c197.94,82.93,372.52,48.22,485.16,3.74A693.21,693.21,0,0,1,494,874.38"
                            />
                            <path
                                className="path-9"
                                fill="none"
                                stroke="#fff"
                                strokeLinejoin="round"
                                strokeWidth="20px"
                                d="M504.82,126.1A699.9,699.9,0,0,0,161.89,504.77,700,700,0,0,0,494,874.38,700,700,0,0,0,837,493.86,699.91,699.91,0,0,0,504.82,126.1Z"
                            />
                        </svg>
                        <div className="accelerate-text">
                            <svg className="text" viewBox="0 0 3512.23 1000">
                                <path
                                    fill="#fff"
                                    d="M218,595H90.8L68.33,658.85h-67L118.21,345.2h72.34L306.94,658.85H240ZM200.89,547,154.16,413.05,107.42,547Z"
                                />
                                <path
                                    fill="#fff"
                                    d="M627.78,369q39.09,30.57,51.68,83.13H613q-10.34-25.61-32.13-40.44t-52.35-14.83q-25.17,0-45.38,12.58T451.63,445.4q-11.24,23.37-11.23,54.82,0,31,11.23,54.38t31.46,35.95q20.22,12.59,45.38,12.58,30.56,0,52.35-14.83T613,547.86h66.51Q666.88,600.43,627.78,631t-98.41,30.56q-45.39,0-80.21-20.45t-53.92-57.29q-19.11-36.84-19.1-83.59,0-47.18,19.1-84t53.69-57.29q34.6-20.44,80.44-20.45Q588.68,338.45,627.78,369Z"
                                />
                                <path
                                    fill="#fff"
                                    d="M1013.78,369q39.09,30.57,51.67,83.13H999q-10.35-25.61-32.13-40.44t-52.35-14.83q-25.17,0-45.39,12.58T837.63,445.4q-11.25,23.37-11.24,54.82,0,31,11.24,54.38t31.45,35.95q20.22,12.59,45.39,12.58,30.56,0,52.35-14.83T999,547.86h66.5q-12.59,52.57-51.67,83.13t-98.41,30.56q-45.4,0-80.22-20.45t-53.92-57.29q-19.11-36.84-19.1-83.59,0-47.18,19.1-84t53.7-57.29q34.59-20.44,80.44-20.45Q974.68,338.45,1013.78,369Z"
                                />
                                <path
                                    fill="#fff"
                                    d="M1221.38,391.93v82.23h112.34v48.53H1221.38v85.38H1347.2v50.78H1158V341.6H1347.2v50.33Z"
                                />
                                <path
                                    fill="#fff"
                                    d="M1502.22,610.32h106.5v48.53H1438.86V341.6h63.36Z"
                                />
                                <path
                                    fill="#fff"
                                    d="M1754.76,391.93v82.23H1867.1v48.53H1754.76v85.38h125.82v50.78H1691.4V341.6h189.18v50.33Z"
                                />
                                <path
                                    fill="#fff"
                                    d="M2137.61,658.85l-74.14-123.57h-27.86V658.85h-63.36V341.6h120.88q55.26,0,84.25,27t29,71q0,36-20,60.22t-57.74,32.35l79.08,126.72Zm-102-167.16h52.12q54.38,0,54.38-48.09,0-22.91-13.26-35.72t-41.12-12.81h-52.12Z"
                                />
                                <path
                                    fill="#fff"
                                    d="M2487.21,595H2360.05l-22.47,63.81h-67L2387.46,345.2h72.34l116.39,313.65h-67ZM2470.14,547l-46.73-133.91L2376.67,547Z"
                                />
                                <path
                                    fill="#fff"
                                    d="M2871,341.6v50.33h-87.63V658.85H2720V391.93h-87.17V341.6Z"
                                />
                                <path
                                    fill="#fff"
                                    d="M3014.31,391.93v82.23h112.34v48.53H3014.31v85.38h125.82v50.78H2951V341.6h189.18v50.33Z"
                                />
                                <path
                                    fill="#fff"
                                    d="M3491.08,582.91q-19.77,36-57.29,55.94t-89.2,20H3231.8V341.6h112.79q51.67,0,89.2,19.55t57.29,55.27q19.77,35.71,19.77,83.36Q3510.85,547,3491.08,582.91Zm-72.57-7.64q28.08-27,28.09-75.49,0-49-28.09-75.95t-79.31-27h-44V602.23h44Q3390.43,602.23,3418.51,575.27Z"
                                />
                            </svg>
                        </div>
                        <svg className="logo" viewBox="0 0 1000 1000">
                            <path
                                className="path-1"
                                fill="none"
                                stroke="#fff"
                                strokeLinejoin="round"
                                strokeWidth="20px"
                                d="M752.7,56.39a746.66,746.66,0,0,1,115.45,61,2.49,2.49,0,0,1,.26.33"
                            />
                            <path
                                className="path-2"
                                fill="none"
                                stroke="#fff"
                                strokeLinejoin="round"
                                strokeWidth="20px"
                                d="M127.63,123l.81-1.1s49-36.58,131.09-68.64"
                            />
                            <path
                                className="path-3"
                                fill="none"
                                stroke="#fff"
                                strokeLinejoin="round"
                                strokeWidth="20px"
                                d="M872.77,874.28c-.47.71-.92,1.43-1.37,2.16,0,0-49.41,38.16-132.62,71"
                            />
                            <path
                                className="path-4"
                                fill="none"
                                stroke="#fff"
                                strokeLinejoin="round"
                                strokeWidth="20px"
                                d="M253.62,943.72a760.63,760.63,0,0,1-125.18-67.28c-.05-.11-.12-.19-.17-.3"
                            />
                            <path
                                className="path-5"
                                fill="none"
                                stroke="#fff"
                                strokeLinejoin="round"
                                strokeWidth="20px"
                                d="M504.82,126.1A693.28,693.28,0,0,1,752.7,56.39C552.52-27.31,373.89,8.63,259.53,53.3"
                            />
                            <path
                                className="path-6"
                                fill="none"
                                stroke="#fff"
                                strokeLinejoin="round"
                                strokeWidth="20px"
                                d="M872.77,874.28c264.72-414.3,5-745-4.36-756.58a700.8,700.8,0,0,1,12.48,132A696.08,696.08,0,0,1,837,493.86"
                            />
                            <path
                                className="path-7"
                                fill="none"
                                stroke="#fff"
                                strokeLinejoin="round"
                                strokeWidth="20px"
                                d="M161.89,504.77A701,701,0,0,1,127.63,123c-16.09,21.54-259.91,360.47.64,753.1"
                            />
                            <path
                                className="path-8"
                                fill="none"
                                stroke="#fff"
                                strokeLinejoin="round"
                                strokeWidth="20px"
                                d="M253.62,943.72c197.94,82.93,372.52,48.22,485.16,3.74A693.21,693.21,0,0,1,494,874.38"
                            />
                            <path
                                className="path-9"
                                fill="none"
                                stroke="#fff"
                                strokeLinejoin="round"
                                strokeWidth="20px"
                                d="M504.82,126.1A699.9,699.9,0,0,0,161.89,504.77,700,700,0,0,0,494,874.38,700,700,0,0,0,837,493.86,699.91,699.91,0,0,0,504.82,126.1Z"
                            />
                        </svg>
                        <div className="accelerate-text">
                            <svg className="text" viewBox="0 0 3512.23 1000">
                                <path
                                    fill="#fff"
                                    d="M218,595H90.8L68.33,658.85h-67L118.21,345.2h72.34L306.94,658.85H240ZM200.89,547,154.16,413.05,107.42,547Z"
                                />
                                <path
                                    fill="#fff"
                                    d="M627.78,369q39.09,30.57,51.68,83.13H613q-10.34-25.61-32.13-40.44t-52.35-14.83q-25.17,0-45.38,12.58T451.63,445.4q-11.24,23.37-11.23,54.82,0,31,11.23,54.38t31.46,35.95q20.22,12.59,45.38,12.58,30.56,0,52.35-14.83T613,547.86h66.51Q666.88,600.43,627.78,631t-98.41,30.56q-45.39,0-80.21-20.45t-53.92-57.29q-19.11-36.84-19.1-83.59,0-47.18,19.1-84t53.69-57.29q34.6-20.44,80.44-20.45Q588.68,338.45,627.78,369Z"
                                />
                                <path
                                    fill="#fff"
                                    d="M1013.78,369q39.09,30.57,51.67,83.13H999q-10.35-25.61-32.13-40.44t-52.35-14.83q-25.17,0-45.39,12.58T837.63,445.4q-11.25,23.37-11.24,54.82,0,31,11.24,54.38t31.45,35.95q20.22,12.59,45.39,12.58,30.56,0,52.35-14.83T999,547.86h66.5q-12.59,52.57-51.67,83.13t-98.41,30.56q-45.4,0-80.22-20.45t-53.92-57.29q-19.11-36.84-19.1-83.59,0-47.18,19.1-84t53.7-57.29q34.59-20.44,80.44-20.45Q974.68,338.45,1013.78,369Z"
                                />
                                <path
                                    fill="#fff"
                                    d="M1221.38,391.93v82.23h112.34v48.53H1221.38v85.38H1347.2v50.78H1158V341.6H1347.2v50.33Z"
                                />
                                <path
                                    fill="#fff"
                                    d="M1502.22,610.32h106.5v48.53H1438.86V341.6h63.36Z"
                                />
                                <path
                                    fill="#fff"
                                    d="M1754.76,391.93v82.23H1867.1v48.53H1754.76v85.38h125.82v50.78H1691.4V341.6h189.18v50.33Z"
                                />
                                <path
                                    fill="#fff"
                                    d="M2137.61,658.85l-74.14-123.57h-27.86V658.85h-63.36V341.6h120.88q55.26,0,84.25,27t29,71q0,36-20,60.22t-57.74,32.35l79.08,126.72Zm-102-167.16h52.12q54.38,0,54.38-48.09,0-22.91-13.26-35.72t-41.12-12.81h-52.12Z"
                                />
                                <path
                                    fill="#fff"
                                    d="M2487.21,595H2360.05l-22.47,63.81h-67L2387.46,345.2h72.34l116.39,313.65h-67ZM2470.14,547l-46.73-133.91L2376.67,547Z"
                                />
                                <path
                                    fill="#fff"
                                    d="M2871,341.6v50.33h-87.63V658.85H2720V391.93h-87.17V341.6Z"
                                />
                                <path
                                    fill="#fff"
                                    d="M3014.31,391.93v82.23h112.34v48.53H3014.31v85.38h125.82v50.78H2951V341.6h189.18v50.33Z"
                                />
                                <path
                                    fill="#fff"
                                    d="M3491.08,582.91q-19.77,36-57.29,55.94t-89.2,20H3231.8V341.6h112.79q51.67,0,89.2,19.55t57.29,55.27q19.77,35.71,19.77,83.36Q3510.85,547,3491.08,582.91Zm-72.57-7.64q28.08-27,28.09-75.49,0-49-28.09-75.95t-79.31-27h-44V602.23h44Q3390.43,602.23,3418.51,575.27Z"
                                />
                            </svg>
                        </div>
                        <svg className="logo" viewBox="0 0 1000 1000">
                            <path
                                className="path-1"
                                fill="none"
                                stroke="#fff"
                                strokeLinejoin="round"
                                strokeWidth="20px"
                                d="M752.7,56.39a746.66,746.66,0,0,1,115.45,61,2.49,2.49,0,0,1,.26.33"
                            />
                            <path
                                className="path-2"
                                fill="none"
                                stroke="#fff"
                                strokeLinejoin="round"
                                strokeWidth="20px"
                                d="M127.63,123l.81-1.1s49-36.58,131.09-68.64"
                            />
                            <path
                                className="path-3"
                                fill="none"
                                stroke="#fff"
                                strokeLinejoin="round"
                                strokeWidth="20px"
                                d="M872.77,874.28c-.47.71-.92,1.43-1.37,2.16,0,0-49.41,38.16-132.62,71"
                            />
                            <path
                                className="path-4"
                                fill="none"
                                stroke="#fff"
                                strokeLinejoin="round"
                                strokeWidth="20px"
                                d="M253.62,943.72a760.63,760.63,0,0,1-125.18-67.28c-.05-.11-.12-.19-.17-.3"
                            />
                            <path
                                className="path-5"
                                fill="none"
                                stroke="#fff"
                                strokeLinejoin="round"
                                strokeWidth="20px"
                                d="M504.82,126.1A693.28,693.28,0,0,1,752.7,56.39C552.52-27.31,373.89,8.63,259.53,53.3"
                            />
                            <path
                                className="path-6"
                                fill="none"
                                stroke="#fff"
                                strokeLinejoin="round"
                                strokeWidth="20px"
                                d="M872.77,874.28c264.72-414.3,5-745-4.36-756.58a700.8,700.8,0,0,1,12.48,132A696.08,696.08,0,0,1,837,493.86"
                            />
                            <path
                                className="path-7"
                                fill="none"
                                stroke="#fff"
                                strokeLinejoin="round"
                                strokeWidth="20px"
                                d="M161.89,504.77A701,701,0,0,1,127.63,123c-16.09,21.54-259.91,360.47.64,753.1"
                            />
                            <path
                                className="path-8"
                                fill="none"
                                stroke="#fff"
                                strokeLinejoin="round"
                                strokeWidth="20px"
                                d="M253.62,943.72c197.94,82.93,372.52,48.22,485.16,3.74A693.21,693.21,0,0,1,494,874.38"
                            />
                            <path
                                className="path-9"
                                fill="none"
                                stroke="#fff"
                                strokeLinejoin="round"
                                strokeWidth="20px"
                                d="M504.82,126.1A699.9,699.9,0,0,0,161.89,504.77,700,700,0,0,0,494,874.38,700,700,0,0,0,837,493.86,699.91,699.91,0,0,0,504.82,126.1Z"
                            />
                        </svg>
                        <div className="accelerate-text">
                            <svg className="text" viewBox="0 0 3512.23 1000">
                                <path
                                    fill="#fff"
                                    d="M218,595H90.8L68.33,658.85h-67L118.21,345.2h72.34L306.94,658.85H240ZM200.89,547,154.16,413.05,107.42,547Z"
                                />
                                <path
                                    fill="#fff"
                                    d="M627.78,369q39.09,30.57,51.68,83.13H613q-10.34-25.61-32.13-40.44t-52.35-14.83q-25.17,0-45.38,12.58T451.63,445.4q-11.24,23.37-11.23,54.82,0,31,11.23,54.38t31.46,35.95q20.22,12.59,45.38,12.58,30.56,0,52.35-14.83T613,547.86h66.51Q666.88,600.43,627.78,631t-98.41,30.56q-45.39,0-80.21-20.45t-53.92-57.29q-19.11-36.84-19.1-83.59,0-47.18,19.1-84t53.69-57.29q34.6-20.44,80.44-20.45Q588.68,338.45,627.78,369Z"
                                />
                                <path
                                    fill="#fff"
                                    d="M1013.78,369q39.09,30.57,51.67,83.13H999q-10.35-25.61-32.13-40.44t-52.35-14.83q-25.17,0-45.39,12.58T837.63,445.4q-11.25,23.37-11.24,54.82,0,31,11.24,54.38t31.45,35.95q20.22,12.59,45.39,12.58,30.56,0,52.35-14.83T999,547.86h66.5q-12.59,52.57-51.67,83.13t-98.41,30.56q-45.4,0-80.22-20.45t-53.92-57.29q-19.11-36.84-19.1-83.59,0-47.18,19.1-84t53.7-57.29q34.59-20.44,80.44-20.45Q974.68,338.45,1013.78,369Z"
                                />
                                <path
                                    fill="#fff"
                                    d="M1221.38,391.93v82.23h112.34v48.53H1221.38v85.38H1347.2v50.78H1158V341.6H1347.2v50.33Z"
                                />
                                <path
                                    fill="#fff"
                                    d="M1502.22,610.32h106.5v48.53H1438.86V341.6h63.36Z"
                                />
                                <path
                                    fill="#fff"
                                    d="M1754.76,391.93v82.23H1867.1v48.53H1754.76v85.38h125.82v50.78H1691.4V341.6h189.18v50.33Z"
                                />
                                <path
                                    fill="#fff"
                                    d="M2137.61,658.85l-74.14-123.57h-27.86V658.85h-63.36V341.6h120.88q55.26,0,84.25,27t29,71q0,36-20,60.22t-57.74,32.35l79.08,126.72Zm-102-167.16h52.12q54.38,0,54.38-48.09,0-22.91-13.26-35.72t-41.12-12.81h-52.12Z"
                                />
                                <path
                                    fill="#fff"
                                    d="M2487.21,595H2360.05l-22.47,63.81h-67L2387.46,345.2h72.34l116.39,313.65h-67ZM2470.14,547l-46.73-133.91L2376.67,547Z"
                                />
                                <path
                                    fill="#fff"
                                    d="M2871,341.6v50.33h-87.63V658.85H2720V391.93h-87.17V341.6Z"
                                />
                                <path
                                    fill="#fff"
                                    d="M3014.31,391.93v82.23h112.34v48.53H3014.31v85.38h125.82v50.78H2951V341.6h189.18v50.33Z"
                                />
                                <path
                                    fill="#fff"
                                    d="M3491.08,582.91q-19.77,36-57.29,55.94t-89.2,20H3231.8V341.6h112.79q51.67,0,89.2,19.55t57.29,55.27q19.77,35.71,19.77,83.36Q3510.85,547,3491.08,582.91Zm-72.57-7.64q28.08-27,28.09-75.49,0-49-28.09-75.95t-79.31-27h-44V602.23h44Q3390.43,602.23,3418.51,575.27Z"
                                />
                            </svg>
                        </div>
                    </div>
                ))}
            </div>
        </IntroWrapper>
    ) : null;
};

export default IntroAnimation;
