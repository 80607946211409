import React, { useContext } from 'react';
import styled from 'styled-components';
import { gsap } from 'gsap';
import TransitionLink from 'gatsby-plugin-transition-link';
import useWindowDimensions from '../../../hooks/useWindowDimensions';
import useMousePosition from '../../../hooks/useMousePosition';
// import { ContactFormPopupContext } from '../../../context/contactFormPopupContext';
import { VideoPopupContext } from '../../../context/videoPopupContext';
import pinWhite from '../../../images/icons/pin-white.png';
import phoneWhite from '../../../images/icons/phone-white.png';
import emailWhite from '../../../images/icons/email-white.png';
import sendWhite from '../../../images/icons/send-white.png';

const Slide = styled.div`
    @keyframes imageNoClipInit {
        0% {
            transform: scale(0);
        }
        100% {
            transform: scale(1);
        }
    }

    @keyframes imageContentInit {
        0% {
            transform: translateY(100%);
        }
        100% {
            transform: translateY(0);
        }
    }

    @keyframes fadeIn {
        0% {
            opacity: 0;
        }
        100% {
            opacity: 1;
        }
    }

    display: block;
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background: #0e1828;
    z-index: 0;
    overflow: hidden;
    &.transition-out {
        transform: translateX(-100%);
        transition: 1.5s ease-out all;
        .do-not-move {
            transform: translateX(100%);
            transition: 1.5s ease-out all;
        }
        .image {
            &.do-not-move {
                transform: translate(100%, -50%);
            }
        }
        .image-no-clip {
            &.do-not-move {
                transform: translate(100%, -50%);
                transition: 1.5s ease-out transform;
            }
        }
    }
    &.active-slide {
        z-index: 6;
    }
    &.next-slide {
        z-index: 5;
    }
    &:nth-child(even) {
        background: #ececec;
        .image-content-overlay {
            h2 {
                color: #fff;
            }
        }
        .image-content {
            .title-wrapper {
                > svg {
                    transform: translateY(0);
                    transition: 0.5s ease-out transform 1s !important;
                }
            }
            h2 {
                color: #0e1828;
            }
        }
        .content {
            color: #0e1828;
        }
        .scroll-to {
            color: #0e1828;
            border-color: #0e1828;
            &:hover {
                color: #fff;
                background: #0e1828;
                border-color: #0e1828;
            }
        }
    }
    &.active-slide {
        .image-no-clip {
            .image-animation {
                transform: scale(1);
                transition: 1s ease-out transform !important;
            }
        }
        .image-content-overlay,
        .image-content {
            .title-wrapper {
                > svg {
                    transform: translateY(0);
                    transition: 1s ease-out transform !important;
                }
            }
            h2 {
                transform: translateY(0);
                transition: 1s ease-out transform !important;
            }
            p {
                transform: translateY(0);
                transition: 1s ease-out transform !important;
            }
        }
        .content {
            .title-wrapper {
                h2 {
                    transform: translateY(0);
                    transition: 1s ease-out transform !important;
                }
            }
            .content-wrapper {
                p {
                    transform: translateY(0);
                    transition: 1s ease-out transform !important;
                }
            }
        }
        .scroll-to {
            opacity: 1;
            transition: 1s ease-out opacity 0.3s;
            &:after {
                height: 8.5rem;
                transition: 0.3s cubic-bezier(0.17, 0.67, 0.83, 0.67) height;
            }
        }
        .image-content-container {
            .contact-links-container {
                .contact-links {
                    transform: translateY(0) !important;
                    transition: 1s ease-out transform !important;
                }
            }
            .send-message-container {
                .send-message {
                    transform: translateY(0) !important;
                    transition: 1s ease-out transform !important;
                }
            }
        }
        &[data-slide='0'] {
            .scroll-to-wrapper {
                button.scroll-to {
                    opacity: 1;
                    transition: 1s ease-out opacity 0.3s;
                }
            }
        }
        &[data-slide='4'] {
            .image {
                img {
                    transform: scale(80);
                    transition: 0.5s ease-out transform !important;
                }
            }
        }
    }
    &[data-slide='0'] {
        .image-no-clip {
            .image-animation {
                transform: scale(0);
                animation: imageNoClipInit 1s ease-out 4s forwards;
                img {
                    margin: -100% 0 0 -50%;
                }
            }
        }
        .image-content {
            .title-wrapper {
                svg {
                    transform: translateY(100%);
                    animation: imageContentInit 1s ease-out 4.3s forwards;
                }
            }
            .content-wrapper {
                p {
                    transform: translateY(100%);
                    animation: imageContentInit 1s ease-out 4.3s forwards;
                }
            }
        }
        .content {
            .title-wrapper {
                h2 {
                    transform: translateY(100%);
                    animation: imageContentInit 1s ease-out 4.3s forwards;
                }
            }
            .content-wrapper {
                p {
                    transform: translateY(100%);
                    animation: imageContentInit 1s ease-out 4.5s forwards;
                }
            }
        }
        .scroll-to-wrapper {
            button.scroll-to {
                display: inline-block;
                position: relative;
                margin-left: auto;
                margin-right: auto;
                font-family: 'Poppins';
                font-size: 1rem;
                line-height: 1.5;
                font-weight: 600;
                color: #fff;
                border: 1px solid #fff;
                border-radius: 30px;
                padding: 1.5rem 2.5rem;
                text-transform: uppercase;
                letter-spacing: 0.3em;
                text-decoration: none;
                text-align: center;
                transition: 1s ease-out opacity;
                opacity: 0;
                pointer-events: all;
                z-index: 16;
                cursor: pointer;
                background: transparent;
                &:hover {
                    background: #fff;
                    color: #0e1828;
                }
            }
        }
    }
    &[data-slide='1'] {
        .image {
            .image-content-overlay {
                h2 {
                    color: #e2246e;
                }
            }
        }
    }
    &[data-slide='2'] {
        .image {
            .image-content-overlay {
                h2 {
                    color: #5fddeb;
                }
            }
        }
    }
    &[data-slide='3'] {
        .image {
            .image-content-overlay {
                h2 {
                    color: #fff;
                }
            }
        }
    }
    &[data-slide='4'] {
        .image {
            width: 100%;
            height: 100%;
            mask-image: none;
            img {
                display: block;
                position: absolute;
                top: 5rem;
                left: 4rem;
                width: 2.6rem;
                height: 2.6rem;
                transform: scale(1);
                transition: 0.5s all ease;
                transform-origin: 45% 35%;
            }
        }
        .image-content {
            padding: 0 18rem;
            text-align: left;
            top: 39%;
            .image-content-container {
                .title-wrapper {
                    h2 {
                        margin-bottom: 1.5rem;
                    }
                }
                .content-wrapper {
                    margin: 0 0 4rem 0;
                    p {
                        display: block;
                        font-family: 'Poppins';
                        font-size: 1.4rem;
                        line-height: 1.4;
                        font-style: normal;
                        max-width: 350px;
                        margin: 0;
                    }
                }
                .contact-links-container {
                    display: block;
                    overflow: hidden;
                    margin-bottom: 4rem;
                    .contact-links {
                        display: block;
                        font-size: 0;
                        margin: 0;
                        transform: translateY(100%);
                        transition: 1s ease-out transform !important;
                        a {
                            display: inline-block;
                            font-family: 'Poppins';
                            font-size: 1.4rem;
                            line-height: 1.35;
                            font-weight: 300;
                            color: #ececec;
                            text-decoration: none;
                            margin: 0 3rem 0 0;
                            pointer-events: all;
                            &.email {
                                i {
                                    width: 1.6rem;
                                    height: 1.6rem;
                                    background: url(${emailWhite}) center/contain no-repeat;
                                }
                            }
                            &.phone {
                                i {
                                    width: 1.6rem;
                                    height: 1.6rem;
                                    background: url(${phoneWhite}) center/contain no-repeat;
                                }
                            }
                            &.directions {
                                i {
                                    width: 2rem;
                                    height: 2rem;
                                    background: url(${pinWhite}) center/contain no-repeat;
                                }
                            }
                            &:last-child {
                                margin: 0;
                            }
                            i {
                                display: inline-block;
                                margin-right: 1rem;
                                vertical-align: text-top;
                                font-size: 0;
                            }
                        }
                    }
                }
                .send-message-container {
                    display: block;
                    overflow: hidden;
                    pointer-events: all;
                    .send-message {
                        display: block;
                        transform: translateY(100%);
                        transition: 1s ease-out transform !important;
                        button {
                            display: inline-block;
                            padding: 1.5rem 2rem;
                            background: #e2246e;
                            color: #fff;
                            font-family: 'Poppins';
                            font-size: 1.1rem;
                            font-weight: 600;
                            line-height: 1.5;
                            letter-spacing: 0.3em;
                            text-transform: uppercase;
                            border: none;
                            border-radius: 25px;
                            pointer-events: all;
                            i {
                                display: inline-block;
                                width: 2.2rem;
                                height: 2.2rem;
                                background: url(${sendWhite}) center/contain no-repeat;
                                vertical-align: middle;
                                margin-left: 2rem;
                            }
                        }
                    }
                }
            }
        }
    }
    .image {
        display: block;
        position: absolute;
        width: 100%;
        top: 50%;
        right: 0;
        left: 0;
        z-index: 2;
        overflow: hidden;
        pointer-events: none;
        transform: translateY(-50%);
        mask-image: url(${(props) => props.image});
        mask-size: 640px auto;
        mask-position: center;
        mask-clip: border-box;
        mask-repeat: no-repeat;
        img {
            display: block;
            width: 100%;
            max-width: 640px;
            height: auto;
            margin: auto;
        }
        .color-overlay {
            display: block;
            position: absolute;
            content: '';
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            width: 100%;
            height: 100%;
            pointer-events: none;
            background: linear-gradient(0deg, #000000 6.86%, rgba(0, 0, 0, 0.17) 100%);
            opacity: 0;
        }
    }
    .image-no-clip {
        display: block;
        position: absolute;
        width: 100%;
        top: 50%;
        transform: translate(0%, -50%);
        z-index: 2;
        overflow: visible;
        pointer-events: none;
        transition: 1.5s ease-out transform;
        .image-animation {
            display: block;
            width: 450px;
            height: auto;
            margin: auto;
            transform: scale(0);
            transition: 1s ease-out transform !important;
            animation: imageNoClipInit 1s ease-out 4s forwards;
            overflow: visible;
            img {
                display: block;
                width: 100%;
                height: auto;
                margin: auto;
                font-size: 0;
                transition: 0.5s ease-out transform;
            }
        }
    }
    .image-content-overlay {
        display: block;
        position: absolute;
        top: -ms-calc(50% - 44px);
        top: calc(50% - 44px);
        left: 0;
        width: 100%;
        max-width: unset;
        text-align: center;
        overflow: hidden;
        padding-left: 7rem;
        padding-right: 7rem;
        h2 {
            font-family: 'Poppins';
            font-size: 6rem;
            line-height: 1.5;
            font-weight: 600;
            letter-spacing: 0.1em;
            margin: 0;
            text-transform: uppercase;
            white-space: nowrap;
            color: #0e1828;
            transform: translateY(100%);
            transition: 1s ease-out transform !important;
        }
    }
    .image-content {
        display: block;
        position: absolute;
        top: -ms-calc(50% - 45px);
        top: calc(50% - 45px);
        width: 100%;
        left: 0;
        right: 0;
        padding-left: 7rem;
        padding-right: 7rem;
        text-align: center;
        overflow: hidden;
        pointer-events: none;
        &.no-clip {
            top: -ms-calc(50% - 21.5px);
            top: calc(50% - 21.5px);
            z-index: 3;
        }
        .title-wrapper {
            overflow: hidden;
            font-size: 0;
            > svg {
                transform: translateY(100%);
                transition: 1s ease-out transform !important;
            }
        }
        h2 {
            font-family: 'Poppins';
            font-size: 6rem;
            line-height: 1.5;
            font-weight: 600;
            letter-spacing: 0.1em;
            margin: 0;
            text-transform: uppercase;
            white-space: nowrap;
            color: #fff;
            transform: translateY(100%);
            transition: 1s ease-out transform !important;
        }
        .content-wrapper {
            overflow: hidden;
        }
        p {
            font-family: 'Source Serif Pro';
            font-size: 1.4rem;
            line-height: 1.25;
            font-style: italic;
            margin: 3rem 0 0 0;
            transform: translateY(100%);
            transition: 1s ease-out transform !important;
            color: #fff;
        }
    }
    .content {
        display: block;
        position: absolute;
        width: 100%;
        bottom: 7rem;
        padding-left: 7rem;
        z-index: 2;
        color: #fff;
        pointer-events: none;
        .title-wrapper {
            overflow: hidden;
            margin-bottom: 1rem;
            h2 {
                font-family: 'Source Serif Pro';
                font-size: 1.6rem;
                line-height: 1.375;
                margin: 0;
                transform: translateY(100%);
                transition: 1s ease-out transform !important;
                span {
                    &.number {
                        font-family: 'Poppins';
                        font-size: 1rem;
                        line-height: 2.2;
                        font-weight: 600;
                        vertical-align: middle;
                        margin-right: 1rem;
                    }
                }
            }
        }
        .content-wrapper {
            overflow: hidden;
            p {
                font-family: 'Poppins';
                font-size: 1.4rem;
                line-height: 1.5;
                max-width: 350px;
                margin: 0;
                transform: translateY(100%);
                transition: 1s ease-out transform !important;
            }
        }
    }
    .scroll-to-wrapper {
        display: block;
        position: absolute;
        width: 100%;
        bottom: 7rem;
        left: 0;
        text-align: center;
        pointer-events: none;
        z-index: 16;
        animation: fadeIn 0.5s ease-out 4.5s forwards;
    }
`;
const ScrollTo = styled(TransitionLink)`
    display: inline-block;
    position: relative;
    margin-left: auto;
    margin-right: auto;
    font-family: 'Poppins';
    font-size: 1rem;
    line-height: 1.5;
    font-weight: 600;
    color: #fff;
    border: 1px solid #fff;
    border-radius: 30px;
    padding: 1.5rem 2.5rem;
    text-transform: uppercase;
    letter-spacing: 0.3em;
    text-decoration: none;
    text-align: center;
    transition: 1s ease-out opacity;
    opacity: 0;
    pointer-events: all;
    z-index: 16;
    &:hover {
        background: #fff;
        color: #0e1828;
    }
`;

const DesktopSlide = ({ ...props }) => {
    const { width, height } = useWindowDimensions();
    const { x, y } = useMousePosition();
    // const { popupChangeHandler } = useContext(ContactFormPopupContext);

    const { videoPopupChangeHandler } = useContext(VideoPopupContext);

    var imagePositionX = Math.max(Math.min((x / width) * 100, 55), 45);
    var imagePositionY = Math.max(Math.min((y / height) * 100, 55), 45);

    return props.index === 0 ? (
        <Slide className={props.classes} data-slide={props.index} image={props.slide.image}>
            <div className={'image-no-clip' + (props.current ? ' do-not-move' : '')}>
                <div className="image-animation">
                    <img
                        src={props.slide.image}
                        alt=""
                        style={{
                            transform:
                                'translate(' + imagePositionX + '%, ' + imagePositionY + '%)',
                        }}
                    />
                </div>
            </div>
            <div className={'image-content no-clip' + (props.current ? ' do-not-move' : '')}>
                <div className="title-wrapper">
                    <svg
                        width="392"
                        height="43"
                        viewBox="0 0 392 43"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <path
                            d="M31.0891 0.932373H38.3637L23.0015 41.5847H16.0122L0.878296 0.932373H8.32403L19.7351 32.5699L31.0891 0.932373Z"
                            fill="white"
                        />
                        <path
                            d="M58.2937 41.5847V0.932373H83.3981V7.35116H65.2117V18.1205H81.6864V24.3681H65.2117V35.1659H83.3981V41.5847H58.2937Z"
                            fill="white"
                        />
                        <path
                            d="M107.328 41.5847V0.932373H114.217V35.0376H132.846V41.5705L107.328 41.5847Z"
                            fill="white"
                        />
                        <path
                            d="M172.416 0.0908203C183.071 0.0908203 193.098 7.93601 193.098 21.23C193.098 34.5241 183.114 42.4406 172.416 42.4406C161.718 42.4406 151.776 34.5954 151.776 21.23C151.776 7.86469 161.746 0.0908203 172.416 0.0908203ZM172.416 35.9077C179.291 35.9077 186.052 31.0437 186.052 21.23C186.052 11.4164 179.291 6.63799 172.416 6.63799C165.541 6.63799 158.765 11.4307 158.765 21.23C158.765 31.0294 165.583 35.9077 172.416 35.9077Z"
                            fill="white"
                        />
                        <path
                            d="M214.028 21.2871C214.028 8.05013 223.942 0.0908203 234.497 0.0908203C244.81 0.0908203 250.943 6.16727 252.783 13.2707L246.307 15.4389C245.095 10.5178 241.315 6.56666 234.497 6.56666C227.679 6.56666 221.075 11.4307 221.075 21.2871C221.075 30.8012 227.622 35.8506 234.554 35.8506C237.282 35.9369 239.96 35.1012 242.154 33.4785C244.349 31.8558 245.933 29.5407 246.65 26.9071L252.954 28.9754C251.114 35.8507 244.924 42.4406 234.554 42.4406C223.599 42.4406 214.028 34.5384 214.028 21.2871Z"
                            fill="white"
                        />
                        <path d="M273.884 41.5847V0.932373H280.888V41.5847H273.884Z" fill="white" />
                        <path
                            d="M324.873 7.42249V41.5847H317.998V7.42249H304.818V0.932373H338.138V7.40821L324.873 7.42249Z"
                            fill="white"
                        />
                        <path
                            d="M356.068 0.932373H364.213L373.955 17.9493L383.754 0.932373H391.485L377.221 24.3681V41.5847H370.346V24.3824L356.068 0.932373Z"
                            fill="white"
                        />
                    </svg>
                </div>
                {props.slide.description ? (
                    <div className="content-wrapper">
                        <p>{props.slide.description}</p>
                    </div>
                ) : (
                    ''
                )}
            </div>
            <div className={'content' + (props.current ? ' do-not-move' : '')}>
                <div className="title-wrapper">
                    <h2>
                        <span className="number">{props.slide.number}</span> {props.slide.subtitle}
                    </h2>
                </div>
                <div className="content-wrapper">
                    <p>{props.slide.content}</p>
                </div>
            </div>
            <div className="scroll-to-wrapper do-not-move">
                <button className="scroll-to" onClick={() => videoPopupChangeHandler(true)}>
                    {props.slide.readMoreText}
                    <svg
                        width="9"
                        height="11"
                        viewBox="0 0 9 11"
                        fill="none"
                        style={{ marginLeft: '.5rem' }}
                    >
                        <path
                            d="M9 5.67871L0.749999 10.4419L0.749999 0.915571L9 5.67871Z"
                            fill="white"
                        />
                    </svg>
                </button>
            </div>
        </Slide>
    ) : props.index === 4 ? (
        <Slide className={props.classes} data-slide={props.index} image={props.slide.image}>
            <div className={'image' + (props.current ? ' do-not-move' : '')}>
                <img src={props.slide.image} alt="" />
            </div>
            <div className={'image-content' + (props.current ? ' do-not-move' : '')}>
                <div className="image-content-container">
                    <div className="title-wrapper">
                        <h2>{props.slide.title}</h2>
                    </div>
                    <div className="content-wrapper">
                        <p>{props.slide.content}</p>
                    </div>
                    <div className="contact-links-container">
                        <div className="contact-links">
                            <a className="email" href="mailto:info@digital-velocity.co.uk">
                                <i></i>info@digital-velocity.co.uk
                            </a>
                            <a className="phone" href="tel:01134601202">
                                <i></i> 0113 460 1202
                            </a>
                            <a
                                className="directions"
                                href="https://goo.gl/maps/TfAk2zyFso84GyKdA"
                                target="_blank"
                                rel="noreferrer"
                            >
                                <i></i> Get Directions
                            </a>
                        </div>
                    </div>
                    {/* <div className="send-message-container">
                        <div className="send-message">
                            <button
                                className="send-a-message"
                                onClick={() => popupChangeHandler(true)}
                            >
                                Send a message <i></i>
                            </button>
                        </div>
                    </div> */}
                </div>
            </div>
        </Slide>
    ) : (
        <Slide className={props.classes} data-slide={props.index} image={props.slide.image}>
            <div className={'image' + (props.current ? ' do-not-move' : '')}>
                <div className="image-animation">
                    <img src={props.slide.image} alt="" />
                </div>
                <div className="image-content-overlay">
                    <h2>{props.slide.title}</h2>
                </div>
            </div>
            <div className={'image-content' + (props.current ? ' do-not-move' : '')}>
                <div className="title-wrapper">
                    <h2>{props.slide.title}</h2>
                </div>
                {props.slide.description ? (
                    <div className="content-wrapper">
                        <p>{props.slide.description}</p>
                    </div>
                ) : (
                    ''
                )}
            </div>
            <div className={'content' + (props.current ? ' do-not-move' : '')}>
                <div className="title-wrapper">
                    <h2>
                        <span className="number">{props.slide.number}</span> {props.slide.subtitle}
                    </h2>
                </div>
                <div className="content-wrapper">
                    <p>{props.slide.content}</p>
                </div>
            </div>
            <div className="scroll-to-wrapper do-not-move">
                <ScrollTo
                    className="scroll-to"
                    to={props.slide.link}
                    exit={{
                        trigger: ({ node, e, exit, entry }) => {
                            gsap.to('.image-content-overlay h2', {
                                y: '-100%',
                                duration: 0.5,
                                delay: 0.5,
                            });
                            gsap.to('.title-wrapper h2', {
                                y: '-100%',
                                duration: 0.5,
                                delay: 0.5,
                            });
                            gsap.to('.social-icons-wrapper', {
                                autoAlpha: 0,
                                duration: 0.5,
                            });
                            gsap.to('.scroll-to-wrapper', {
                                autoAlpha: 0,
                                duration: 0.5,
                            });
                            gsap.to('.content', {
                                autoAlpha: 0,
                                duration: 0.5,
                            });
                            gsap.to('.next-slide', {
                                autoAlpha: 0,
                                duration: 0.5,
                            });
                            gsap.to('.dots', {
                                autoAlpha: 0,
                                duration: 0.5,
                            });
                            gsap.to('.image .image-animation', {
                                transform: 'scale(0)',
                                duration: 0.5,
                                delay: 1.5,
                            });
                        },
                        length: 2,
                    }}
                    entry={{
                        delay: 2,
                    }}
                >
                    {props.slide.readMoreText}
                </ScrollTo>
            </div>
        </Slide>
    );
};

export default DesktopSlide;
