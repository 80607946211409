import React from 'react';
import useWindowDimensions from '../hooks/useWindowDimensions';
import MobileSlider from '../components/custom/home/mobileSlider';
import DesktopSlider2 from '../components/custom/home/desktopSlider2';
import HomepageSlideContextProvider from '../context/homepageSlideContext';
import IntroAnimation from '../components/custom/home/introAnimation';
import VideoPopup from '../components/popups/videoPopup';
import VideoPopupContextProvider from '../context/videoPopupContext';

import Layout from '../components/layout';
import Seo from '../components/seo';

const IndexPage = ({ transitionStatus }) => {
    const { width } = useWindowDimensions();
    const breakpoint = 1024;

    return (
        <HomepageSlideContextProvider>
            <Layout hideFooter={true}>
                <Seo title="Home" />
                <IntroAnimation delay={4000} />
                <VideoPopupContextProvider>
                    {width < breakpoint ? <MobileSlider /> : <DesktopSlider2 />}
                    <VideoPopup />
                </VideoPopupContextProvider>
            </Layout>
        </HomepageSlideContextProvider>
    );
};

export default IndexPage;
