import React, { createContext, useState } from 'react';

export const VideoPopupContext = createContext({
    showVideoPopup: '',
    videoPopupChangeHandler: () => {},
});

const VideoPopupContextProvider = (props) => {
    const [showVideoPopup, setShowVideoPopup] = useState('');

    const videoPopupChangeHandler = (showVideoPopup) => {
        setShowVideoPopup(showVideoPopup);
    };

    return (
        <VideoPopupContext.Provider
            value={{
                showVideoPopup: showVideoPopup,
                videoPopupChangeHandler: videoPopupChangeHandler,
            }}
        >
            {props.children}
        </VideoPopupContext.Provider>
    );
};

export default VideoPopupContextProvider;
